@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.action-plan {
  &__modal {
    position: relative;
  }
  &__creator {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__textarea {
    @extend .textarea;
    height: 150px;
    position: relative;

    &:hover {
      border: 1px solid gray;
    }

    &.viewed {
      border: 3px solid $green;
    }
  }

  &__viewed-icon {
    position: absolute;
    top: -22px;
    right: -22px;
  }

  &__read-badge {
    position: absolute;
    color: white;
    right: -10px;
    top: -10px;
    border-radius: 20px;
    font-size: 12px;
    z-index: 2;
    padding: 5px;
    background-color: $green-hover;
  }
}
