@import '../../styles/common';
@import '../../styles/animations';

.stats-page {
  @extend .card;
  animation: fadeIn 1s;

  &__chart {
    width: 500px;
    height: 500px;
    margin: 0px auto;
    border: 1px solid silver;
    border-radius: 6px;

    &--line {
      width: 50%;
      margin: 0px auto;
    }
  }
}
