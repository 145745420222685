@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.dashboard-status {
  @extend .card;
  width: 100%;

  h3 {
    @extend .card_title;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;

    .stat {
      @extend .card;
      text-align: center;
      font-size: 14px;
      position: relative;
      background-color: white;
      border-radius: 6px;
      padding: 15px 30px;
      height: 120px;
      margin: 0px auto;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        .stat__redirect-btn,
        .stat__search-btn {
          display: block;
        }
      }

      &__title {
        font-weight: 600;
      }

      &__info {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      &__redirect-btn {
        display: none;
        position: absolute;
        right: 15px;
        bottom: 5px;
        color: $subheader-background;
        transform: scaleX(-1);
      }

      &__search-btn {
        display: none;
        position: absolute;
        left: 15px;
        bottom: 5px;
        color: $subheader-background;
      }
    }
  }
}
