@import '../../../styles/common.scss';

.evaluations-remaining-message {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;

  img {
    height: 100%;
    display: block;
  }

  .message-title {
    color: $green;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: bold;
    width: fit-content;
  }

  p {
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}
