@import '../../../styles/common';
@import '../../../styles/variables';

.filtered-users {
  &__load-data {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #838ab1;
    }
  }

  &__filters {
    width: 100%;
    display: flex;
    align-items: center;

    &__textfield {
      width: 20%;
    }

    &__select {
      flex: 1;
      margin-left: 20px;
    }

    &__no-area-msg {
      display: flex;
      justify-content: space-between;
      border: 1px solid $warning;
      color: $warning;
      font-weight: bolder;
      padding: 2px 10px;
      margin-left: 20px;
      border-radius: 6px;
      font-size: 14px;

      button {
        display: inline-block;
        margin-left: 20px;
        font-size: 14px;
      }
    }

    &__switch {
      width: 130px;
      margin-left: 20px;
    }
  }

  &__table-container {
    @extend .card;
    width: 100%;
    margin: 0;
    h3 {
      @extend .card_title;
    }
  }

  &__table {
    flex: 1;
    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border: 1px solid rgb(223, 223, 223);
      margin-top: 20px;
      padding: 0 5px;
      background-color: rgb(230, 230, 230);

      h4 {
        flex: 1;
        padding: 12px 2px;
      }
    }

    &__data {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid silver;
      padding: 0 5px;

      &:hover {
        background-color: #f0f0f0;
      }

      &--selected {
        background-color: #ebebeb;
      }

      &--no-area {
        background-color: $warning-light;

        &:hover {
          background-color: $warning-light-secondary;
        }
      }

      div {
        flex: 1;
        padding: 10px 2px;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .no-area {
        color: $warning;
      }

      .is-active {
        padding: 0;
      }
    }

    &__loading,
    &__error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__actions {
      width: 50px !important;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .icon-container {
        cursor: pointer;
      }
    }

    .no-results {
      padding: 15px 5px;
    }

    .area {
      display: flex;
    }

    .is-admin {
      flex: none;
      width: 70px;
      text-align: center;
    }

    .entry {
      flex: none;
      width: 105px;
    }

    .role {
      flex: none;
      width: 100px;
    }

    .is-active {
      flex: none;
      width: 60px;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.remove-filters {
  button {
    text-transform: none;
    margin: 4px 0 -16px 0;

    span {
      margin-top: 2px;
    }
  }
}
