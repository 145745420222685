@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.user-evaluation {
  @extend .card;
  flex: 1;
  max-width: 33%;
  max-height: 280px;
  border: 1px solid transparent;
  position: relative;

  &:nth-child(2) {
    margin: 0 15px;
  }

  &__label {
    font-size: 18px;
    color: rgb(44, 50, 109);
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__area-name {
    position: absolute;
    top: -27px;
    left: 6px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background-color: $subheader-background;
    padding: 5px 30px 5px 10px;
    border-radius: 6px 6px 0 0;
    width: fit-content;
    margin-bottom: 10px;
    clip-path: polygon(0 0, 90% 0%, 100% 100%, 0% 100%);
  }

  &__competences-title {
    color: rgb(159, 159, 159);
  }

  &__competences {
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(227, 227, 227);
  }

  &__competence {
    font-size: 14px;
    color: silver;
    line-height: 22px;
  }

  &__reopen-flag {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid $warning;
    color: $warning;
    font-size: 12px;
  }

  &--done {
    border: 2px solid $green;
  }
}
