@import '../../../styles/variables';

.add-user-modal {
  &__content {
    margin-bottom: 20px;
  }

  &__warning-message {
    color: $warning;
    margin-bottom: 20px;
    font-size: 14px;
  }
}
