@import '../../../styles/variables.scss';

.evaluation-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  &__page {
    font-size: 18px;
  }

  &__competence-name {
    display: flex;
    justify-content: space-between;
    font-size: 26px;
    margin-bottom: 25px;
    font-weight: 800;
    border-left: 12px solid $green;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 10px 10px 20px;
    color: $green;
  }

  &__competence-description {
    font-size: 16px;
    margin: 0 15px;
    padding: 20px;
    background-color: #e1eeeb;
    border-radius: 4px;
    width: fit-content;
    line-height: 22px;
  }

  &__comments-title {
    font-size: 26px;
    margin-bottom: 25px;
    font-weight: 800;
    border-left: 12px solid gray;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 10px 10px 20px;
  }

  &__comments-description {
    font-size: 16px;
    margin: 0 15px;
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 4px;
    width: fit-content;
    line-height: 22px;
  }

  &__bonus {
    margin-top: 15px;

    &__title {
      display: flex;
      justify-content: flex-start;
      margin: 30px 15px 15px;
      font-size: 20px;
      font-weight: 600;
    }

    &__unselect {
      margin-left: 25px;
      font-size: 12px;
      background-color: #6775a0;
      color: white;
      border-radius: 6px;
      padding: 4px 10px;
      cursor: pointer;
    }

    &__label {
      margin: 15px;

      p {
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 5px;
        padding: 15px;
        border: 1px solid #dbdbdb;
        border-radius: 6px;
      }
    }

    &__users {
      display: flex;
    }

    &__user {
      border: 2px solid transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 200px;
      text-align: center;
      padding: 25px 10px;
      margin: 30px 10px;
      border-radius: 6px;
      background-color: #f6f6f6;
      cursor: pointer;

      &:hover {
        background-color: #e6e5e5;
      }

      &__name {
        margin-top: 20px;
        font-weight: 600;
      }

      &--disabled {
        cursor: default;

        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }

  .user-selected {
    border: 2px solid #6775a0;
    background-color: #e9e9e9;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 20px 0;
    flex: 1;
  }

  &__dots {
    display: flex;
    width: fit-content;
  }

  &__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid silver;
    background-color: #f6f6f6;
    margin: 10px;
  }

  &__dot--current {
    background-color: #7f90c1;
    border-color: #6a79a7;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 32px;
    color: silver;
  }

  &__submit-button {
    width: 100px;
  }
}
