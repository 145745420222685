@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.area-users-data {
  @extend .card;
  height: 100%;
  margin-bottom: 10px;
  flex: 1;

  h3 {
    @extend .card_title;
  }

  &__select {
    margin-bottom: 10px;
  }

  &__users {
    display: flex;
    gap: 5px;
    overflow: auto;
    flex: 1;
    margin-top: 10px;

    &-avatar {
      cursor: pointer;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100px;

    &-avatar {
      cursor: pointer;
    }

    &-score {
      font-weight: bolder;
    }
  }
}
