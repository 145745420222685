@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.previous-evaluations {
  &__evaluation {
    display: flex;
    margin-bottom: 15px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    padding: 15px 0;
    background-color: #fafbfd;

    &__data {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__name {
      padding-left: 15px;
      font-size: 18px;
      font-weight: bolder;
      margin-top: 20px;
    }

    &__date {
      padding-left: 15px;
      font-size: 14px;
    }

    &__progress {
      display: flex;
      width: 50%;
    }

    &__total-score {
      padding: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-size: 18px;
      font-weight: bolder;
      margin-right: 10px;
    }

    &__points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      margin-top: 10px;
      font-weight: bolder;
    }
  }

  &__evaluation-in-progress {
    font-size: 14px;
    padding: 15px;
  }

  &__category-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__category {
    padding: 4px 6px;
    margin-left: 6px;
    border-radius: 3px;
    font-size: 10px;
  }

  &__report {
    display: flex;
    padding: 0 10px;
    align-items: center;

    .report_button {
      &:hover {
        background-color: $subheader-background;
        color: white;
      }
    }
    .comments_button,
    .action-plan_button {
      margin-right: 10px;
    }
  }

  &__generating-report {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    width: 200px;
    padding: 5px 10px;
    margin-left: 10px;
  }

  &__no-category {
    padding: 4px 6px;
    margin-top: 20px;
    margin-left: 10px;
    border-radius: 3px;
    font-size: 10px;
  }

  &__report-error {
    font-size: 12px;
    margin-left: 15px;
    color: #a65e5e;
  }

  &__no-data {
    padding: 10px 20px;

    p {
      margin-bottom: 15px;
    }
  }
}
