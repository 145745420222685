.comments__modal {
  ul {
    list-style: none;
    padding: 0;
  }

  &__comment {
    padding: 15px;
    margin: 0 0 15px;
    border-radius: 6px;
    border: 1px solid silver;
  }
}
