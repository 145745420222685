@import '../../../styles/common.scss';

.demand-form {
  &-textarea {
    @extend .textarea;
    height: 100px;
    margin-top: 20px;
  }

  &-stars-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 18px;

    span {
      margin-right: 20px;
    }
  }
}
