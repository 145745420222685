@import '../../../styles/common.scss';

.goal-form-textarea {
  @extend .textarea;
  height: 150px;
}

.condition-masssage {
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 20px;
}
