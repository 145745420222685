.areas-assign {
  margin-top: 25px;
  display: flex;
  align-items: center;
  height: 60px;

  .form-input-wrapper {
    width: 696px;
    margin-bottom: unset;
  }

  &__header {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
  }
}
