@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.search {
  width: 500px;
  padding: 10px;
  min-width: 300px;
  position: relative;

  &__posulated-users-quantity {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    color: white;
    border-radius: 50%;
    background-color: $green;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  &-container {
    @extend .card;
    height: auto;
    background-color: $child-cards-background;
    padding: 10px 20px;

    &__job {
      font-weight: bolder;
    }

    &__area {
      margin-top: 5px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid silver;

      svg {
        margin-right: 15px;
      }
    }

    p {
      margin: 5px 0px;
      flex: 1;
    }

    span {
      margin-left: 10px;
      line-height: 22px;
    }

    &_info-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      align-items: center;

      .postulate {
        background-color: $green;
      }

      .postulate:hover {
        background-color: $green-hover;
      }

      &__postulations {
        font-size: 12px;
        color: silver;
      }
    }
  }
}
