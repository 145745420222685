@import '../../styles/variables.scss';

.header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background: $header-background;
  width: 100vw;
  max-width: 100%;

  button {
    background-color: inherit;
    font-weight: bold;
    font-size: 15px;
  }

  button:hover {
    color: $primary-text-color;
    background-color: inherit;
  }

  a {
    text-decoration: none;
    color: white;
  }

  &__logo {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    img {
      height: 34px;
      margin-right: 40px;
      margin-bottom: 4px;
    }

    &__application-name {
      margin: 14px 0 0 30px;
      color: white;
    }
  }

  &__users-search {
    width: 30vw;
  }

  &__app-name {
    font-size: 18px;
    margin-top: 2px;
    color: silver;
    cursor: default;
  }

  &--hide {
    display: none;
  }
}

// Responsive

@media (max-width: 600px) {
  .header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__logo {
      margin-bottom: 0px;

      h2 {
        display: none;
      }
    }
  }
}
