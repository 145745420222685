.user-detail-page__development-history {
  .move {
    padding: 8px 0;
    border-bottom: 1px solid silver;

    .no-entry {
      color: rgb(240, 165, 165);
    }
  }
}
