@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.super-admin-dashboard {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__top {
    display: flex;
    justify-content: space-between;
    height: 50vh;
    gap: 10px;
  }

  &__bottom {
    display: flex;
  }
}
