@import '../../styles/common';
@import '../../styles/variables';
@import '../../styles/animations';

.status-page {
  @extend .card;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  .stats-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .stat {
    position: relative;
    box-shadow: $primary-shadow;
    color: $primary-text-color;
    background-color: white;
    width: 300px;
    height: 300px;
    border-radius: 12px;
    padding: 40px 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &:hover {
      cursor: pointer;
      .stat__redirect-btn {
        display: block;
      }
    }

    &__data {
      font-size: 48px;
      font-weight: bolder;
      color: $subheader-background;
    }

    &__info {
      font-size: 24px;
      font-weight: bolder;
      color: $subheader-background;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__redirect-btn {
      display: none;
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: $subheader-background;
      transform: scaleX(-1);
    }
  }
}
