@import '../../../styles/variables.scss';

.feedback {
  margin-bottom: 12px;

  &__date {
    font-size: 14px;
    padding: 2px 8px;
    background-color: $green;
    color: white;
    width: fit-content;
    border-radius: 6px 6px 0 0;
    margin-left: 4px;
    margin-bottom: 10px;
  }

  &__text {
    box-shadow: $primary-shadow;
    padding: 10px;
    border-radius: 4px;
  }
}
