

.user-score-tooltip {
  background-color: '#5a5a5a';
  border: '1px solid gray';
  border-radius: '6px';
  padding: 5;
  font-size: 1rem;

  &.boss{
    border: 1px solid red;
  }

  .name {
    text-decoration: underline;
    font-style: italic;
  }

  p{
    padding: 4px 0;
  }
}