@import '../../styles/common';
@import '../../styles/animations';

.evaluation-response-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;

    &__back {
      cursor: pointer;
    }
  }
}
