@import '../../../styles/common.scss';

.personal-data {
  @extend .card;
  display: flex;
  color: black;
  flex: 1;
  margin-left: 20px;
  padding-top: 40px;

  &__form {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    &__no-data {
      color: silver;
      margin-top: 30px;
    }

    &__side {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      flex: 1;

      &__textarea {
        @extend .textarea;
        margin-top: 10px;
        background: transparent;
      }

      &__label-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__custom-control {
        display: flex;
        align-items: center;
        margin: 10px 0;
      }
    }
  }
  &__actions {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
