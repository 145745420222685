@import '../../../styles/common';

.user-goal-status {
  @extend .card;
  height: fit-content;

  h3 {
    @extend .card_title;
  }

  .stats-container {
    display: flex;
    gap: 10px;
  }

  .stat {
    position: relative;
    box-shadow: $primary-shadow;
    color: $primary-text-color;
    width: 130px;
    height: 150px;
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 14px;
    }

    &__data {
      font-size: 16px;
      font-weight: bolder;
      color: $subheader-background;
    }

    &__info {
      font-size: 14px;
      font-weight: bolder;
      color: $subheader-background;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
