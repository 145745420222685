@import '../../../styles/variables';

.competences-templates {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  &__title {
    border-top: 1px solid silver;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.competence-template {
  border: 1px solid $green;
  width: fit-content;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  &__name {
    background-color: $green;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    margin-bottom: 15px;
  }

  &__competence {
    padding: 5px 0;
    flex: 1;
  }

  &__assign-button {
    margin-top: 15px;
  }
}
