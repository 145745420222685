@import '../../styles/common';

.user-detail-page {
  display: flex;
  gap: 20px;

  &__title {
    @extend .card_title;
  }

  .progress {
    height: fit-content;
  }

  &__left-column,
  &__right-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
  }

  &__personal-data-container,
  &__development-history-container {
    @extend .card;
    display: flex;
    color: black;
    position: relative;
    height: fit-content;
  }

  &__personal-data {
    &__title {
      color: gray;
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__info {
      margin-bottom: 10px;
      padding-left: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #e4e4e4;
    }

    &__children {
      display: flex;

      div {
        flex: 1;
      }
    }

    &__studies {
      list-style: none;
      padding-left: 35px;
    }

    &__study {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #e4e4e4;
    }
  }

  &__area-container {
    @extend .card;
    display: flex;
    color: black;
    margin-left: 20px;
    justify-content: flex-end;
    position: relative;
    min-width: 480px;
    max-width: 1000px;

    &__back-button {
      display: flex;
      justify-content: flex-end;

      button {
        width: fit-content;
      }
    }
  }

  &__area {
    position: relative;
  }
}
