@import '../../../styles/variables';

.load-areas-form {
  &__content {
    padding: 15px;

    p {
      line-height: 24px;
      color: $warning;

      a {
        color: $warning-dark !important;
        font-weight: bold;
      }
    }

    input {
      margin-top: 25px;
      padding: 10px;
      border: 1px solid silver;
      border-radius: 6px;
    }
  }

  &__link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
  }

  &__actions {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      width: fit-content;
    }
  }
}
