.competences-templates-form {
  display: flex;
  flex-direction: column;

  .form-input-wrapper {
    margin: 10px 0;
  }

  button {
    margin-top: 60px;
  }
}
