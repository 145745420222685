@import '../../../styles/animations.scss';

.area-form {
  animation: fadeIn 0.3s;

  &__ponderations-container {
    display: flex;
    margin-top: 10px;
    gap: 5px;
  }
}
