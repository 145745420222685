@import '../../styles/common.scss';

.area-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  animation: fadeIn 1s;

  &__container {
    display: flex;
    flex: 1;

    h3 {
      @extend .card_title;
    }
  }
}
