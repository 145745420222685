.competences-tooltip {
  background-color: white;
  font-size: 14px;
  color: black;
  padding: 10px;
  border-radius: 6px;

  &__points {
    margin-left: 10px;
    color: gray;
  }
}
