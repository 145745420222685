@import '../../../styles/variables';

.selected-user {
  width: 100%;
  margin-top: 10px;
  padding-left: 20px;
  border-left: 1px solid silver;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__user-data {
    margin-left: 20px;
  }

  &__user-name {
    font-size: 18px;
    margin-bottom: 2px;
    font-weight: bold;
  }

  &__user-role-label {
    font-size: 14px;
  }

  &__impacts {
    padding: 6px;
    border: 1px solid silver;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    width: 100px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  &__option-label {
    font-size: 20px;
    font-weight: bold;
    margin: 0 80px;
    padding: 10px 15px;
    color: $green;
    flex: 1;
    text-align: center;
  }

  &__content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
