@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.categories-ranking {
  @extend .card;
  flex: 1;

  h3 {
    @extend .card_title;
    margin-bottom: 5px;
  }

  &-container {
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;

    .user {
      border-bottom: 1px solid $border-light;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 6px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &-row {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-data {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
      }

      &-position {
        margin-right: 10px;
        font-size: 14px;
      }

      &-name {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 4px;
      }

      &-points {
        padding: 10px;
        text-align: center;
        font-weight: 600;
      }

      &-area {
        font-size: 12px;
      }
    }
  }

  &__evaluation-process-message {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    padding: 10px 0;

    img {
      margin-bottom: 20px;
      width: 100%;
    }

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: 1400px) {
  .user-area {
    display: none !important;
  }
}
