@import '../../../styles/variables.scss';

.goals-table {
  margin-top: 10px;

  td {
    padding: 8px;
  }

  th {
    text-align: center;
  }

  &__table-header {
    background-color: rgb(230, 230, 230);
  }

  .avatars-container {
    display: flex;
    max-width: 200px;
    gap: 5px;
    overflow: auto;
  }

  .avatar-cell {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      text-align: center;
    }
  }

  .icon-cell {
    text-align: center;
  }

  .feedback-icon {
    position: relative;
    width: fit-content;
    margin: 0px auto;

    .feedbacks-qty {
      position: absolute;
      background-color: $green;
      width: 10px;
      height: 10px;
      color: white;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 12px;
      top: -5px;
      right: -5px;
    }
  }

  .text-cell {
    &__note,
    &__state {
      text-align: center;
      width: 100px;
      margin: 0px auto;

      &.approbed {
        background-color: $green;
        color: white;
        border-radius: 6px;
        padding: 2px 5px;
      }

      &.failed {
        background-color: $warning;
        color: white;
        border-radius: 10px;
        padding: 2px 5px;
      }
    }
    &__title {
      text-align: center;
    }
  }

  .actions-icons {
    display: flex;
    justify-content: center;
  }

  .title-column {
    // width: 50%;
    width: fit-content;
  }
}
