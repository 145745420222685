@import '../../styles/variables.scss';

.subheader {
  position: relative;
  grid-area: subheader;
  display: flex;
  justify-content: space-between;
  padding: 0;
  letter-spacing: 0.5px;
  position: relative;
  background-color: $subheader-background;
  width: 100vw;
  max-width: 100%;

  &__loading {
    position: absolute;
    width: 100%;
    bottom: 0px;
    color: rgb(6, 152, 209);
    height: 3px;
  }

  &__section {
    text-decoration: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary-text-color;
    transition: all 0.1s ease-in;

    div {
      padding: 5px;
      font-size: 14px;
    }

    #company-list,
    #configurations-list {
      // display: none;
      max-height: 0px;
      position: absolute;
      transform: translateY(120px);
      z-index: 10;
      box-shadow: $primary-shadow;
      top: -74px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;

      a {
        width: 100%;
        list-style: none;
        text-decoration: none;
        background: $subheader-menu-background;
        padding: 10px;
        padding: 18px 5px;
        text-align: center;
        color: #2b508d;
        border-bottom: 1px solid silver;
        font-size: 14px;
      }

      a:hover {
        background-color: $subheader-background-hover;
        color: $secondary-text-color;
      }
    }

    &.company:hover {
      #company-list {
        max-height: 500px;
        transition: all 1s ease-out;
        display: flex;
        flex-direction: column;
      }
    }

    &.configurations:hover {
      #configurations-list {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__section:hover {
    background-color: $subheader-background-hover;
  }

  .active {
    background-color: $subheader-menu-background;
    border-bottom: 1px solid rgb(211, 211, 211);
    color: #2b508d;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: -2px;
  }

  &--fixed {
    position: fixed;
    width: 100vw;
    z-index: 9;
    top: 0;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgb(138, 138, 138);

    .subheader__section {
      transition: all 0.2s ease-out;
      flex-direction: row;
      padding: 10px 0;
      font-size: 14px;
    }

    .active {
      border-radius: 0;
    }

    .company:hover {
      #company-list {
        transition: none;
        margin-top: -2px;
        font-size: 16px;
      }
    }
  }
}

// Responsive

@media (max-width: 600px) {
  .subheader {
    &__section-name {
      display: none;
    }

    .company:hover {
      #company-list {
        margin-top: -30px;
        font-size: 16px;
        position: absolute;
        width: fit-content;
        right: 10px;
        top: -18px;

        a {
          width: 100%;
          padding: 20px 25px;
        }
      }
    }
  }
}
