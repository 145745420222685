@import '../../../styles/common';
@import '../../../styles/animations';

.create-evaluation {
  @extend .card;

  h3 {
    @extend .card_title;

    div {
      cursor: pointer;
    }
  }

  &_step {
    margin-top: 30px;
  }

  &___text {
    padding: 10px;
    font-size: 14px;
    margin-top: 10px;
    width: fit-content;
    background-color: #e9e5f4;
    border-radius: 4px;
  }

  &_form-container {
    flex: 1;
  }

  &_step-container {
    display: none;

    &.show {
      display: block;
    }
  }

  &_actions-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .button-text {
      margin: 0 10px;
    }
  }
}

.sending-message {
  margin: 15px 0 15px;

  p {
    margin-bottom: 10px;
  }
}
