@import '../../../styles/variables';

.area-user {
  padding: 10px;
  margin: 15px 0;
  border: 1px solid silver;
  border-radius: 6px;
  display: flex;
  width: 400px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f8f8f8;
  }

  &--selected {
    background-color: #f8f8f8;
    border: 2px solid $subheader-background;
    font-weight: bold;

    .area-users-list__user-name {
      font-weight: bold;
    }
  }

  &__user-data {
    margin-left: 20px;
  }

  &__user-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
    font-weight: bold;
  }

  &__user-role-label {
    font-size: 12px;
  }

  &__confirmed,
  &__need-confirmation {
    position: absolute;
    color: $warning;
    top: 4px;
    right: 4px;
    font-size: 14px;
  }

  &__confirmed {
    color: $green;
  }

  &__need-confirmation {
    color: $warning;
  }

  &__range {
    padding: 2px 4px;
    background-color: #b4b4b4;
    color: white;
    border-radius: 4px;
    margin-left: 15px;
    font-size: 12px;
  }
}
