@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.evaluation {
  position: relative;
  padding: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 6px;
  margin-bottom: 40px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  box-shadow: $primary-shadow;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px 0;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid silver;
    padding-top: 15px;
  }

  &--done {
    border-left: 3px solid $green;
  }

  &__check {
    position: absolute;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    background-color: $green;
    top: -10px;
    right: -10px;
    color: white;
  }
}

// Responsive

@media (max-width: 600px) {
  .user-evaluation {
    &__header {
      flex-direction: column;

      &__responses {
        flex-direction: column;
        margin-top: 10px;
      }

      &__response {
        margin: 5px 0;
      }
    }

    &__body {
      flex-direction: column;
    }
  }
}
