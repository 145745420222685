@import '../../../styles/variables.scss';

.evaluation-progress {
  margin-top: 10px;
  height: 10px;

  &-pending {
    color: $progress-assistance;
  }

  &-done {
    color: $progress-objetives;
  }

  &__error {
    color: rgb(155, 15, 15);
    font-size: 18px;
    font-weight: bold;
  }
}
