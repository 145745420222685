@import '../../../styles/variables';

.modal-area-detail {
  min-height: 120px;
  display: flex;
  flex-direction: column;

  &__loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__users {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }

  &__connector {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }

  &__user {
    display: flex;
    align-items: center;
    border: 1px solid silver;
    padding: 10px 15px;
    border-radius: 6px;
    margin: 0 10px;
    cursor: pointer;
  }

  &__user-data {
    margin-left: 15px;
    text-align: left;
  }

  &__user-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  &__user-role-label {
    font-size: 12px;
    color: silver;
  }

  &__without-boss {
    padding: 15px 10px;
    color: silver;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__without-boss-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    background-color: white;

    svg {
      font-size: 18px;
    }
  }

  &__no-boss {
    padding: 15px 10px;
    color: $warning;
    margin-bottom: 25px;
  }
}
