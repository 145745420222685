@import '../../../styles/variables';

.related-evaluations-detail {
  padding-left: 20px;
  padding-right: 5px;
  flex: 1;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    color: $subheader-background;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-right: 20px;
  }

  &__container {
    list-style: none;
    padding-top: 15px;
    width: 100%;
  }

  &__evaluation {
    padding: 15px;
    border: 1px solid silver;
    border-radius: 6px;
    margin-bottom: 10px;
  }

  &__evaluation-data-container {
    display: flex;
  }

  &__evaluation-data {
    flex: 1;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    b {
      margin-right: 5px;
    }
  }

  &__icon {
    margin-left: 15px;
  }

  &__answers {
    margin-top: 15px;
  }

  &__answers-title {
    font-weight: bold;
    font-size: 14px;
    color: $subheader-background;
  }

  &__answers-container {
    list-style: none;
  }

  &__answer {
    border-bottom: 1px solid #dddddd;
    margin-top: 4px;
  }

  &__answer-competence-name {
    font-weight: bold;
    padding: 6px;
    background-color: #f4f4f4;
    color: $subheader-background;
  }

  &__answer-data-container {
    display: flex;
  }

  &__answer-data {
    flex: 1;
    font-size: 14px;
    padding: 8px 0;

    b {
      margin-right: 5px;
    }
  }

  &__empty {
    padding: 15px 0;
    color: silver;
    font-size: 18px;
  }

  &__answers-empty {
    padding-top: 10px;
    font-size: 14px;
    color: silver;
  }
}
