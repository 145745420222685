@import '../../styles/common';
@import '../../styles/variables';
@import '../../styles/animations';

.mange-company-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  &__form {
    display: flex;
    flex-direction: column;

    h3 {
      text-align: left;
      height: 50px;
    }

    &__container {
      display: flex;
      gap: 25px;

      &__data {
        flex: 1;
      }

      &__ponderations {
        width: 140px;
      }

      &__categories {
        width: 140px;
      }

      &__side {
        width: 305px;
      }
    }

    &__warning-message {
      color: $warning;
      border: 1px solid $warning;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 15px;
      width: fit-content;
    }

    &__button {
      width: fit-content;
    }

    &__textarea {
      @extend .textarea;
      height: 135px !important;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
  }
}
