@import '../../styles/animations';
@import '../../styles/common';

.calendar-page {
  @extend .card;
  animation: fadeIn 1s;
  height: 100%;

  &__title {
    @extend .card_title;
  }
}
