@import '../../styles/common.scss';
@import '../../styles/variables';

.areas-table {
  @extend .card;
  min-width: 750px;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  th,
  td {
    text-align: center;
    flex: 1;
    padding: 0px !important;
  }
  tr {
    height: 60px;
  }

  &__table-header {
    background-color: rgb(230, 230, 230);
  }

  .area-li {
    list-style: none;
  }

  &__filters {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__input-filter,
  &__select-filter {
    width: 33%;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__parent-area-name {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__level-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
  }

  &__level {
    margin: 0 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    padding: 5px 6px;
    cursor: default;

    &--1 {
      background-color: $green-secondary;
    }

    &--2 {
      background-color: #dfdfe9;
    }

    &--2-without-boss {
      background-color: #effffc;
    }
  }

  &__without-boss-icon {
    margin: 0 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    padding: 4px;

    svg {
      font-size: 20px;
    }
  }

  &__area-chip {
    margin: 4px;
  }

  &__more-areas {
    margin: 4px;
    border: 1px solid gray;
    color: gray;
    border-radius: 20px;
    padding: 6px 12px;
    cursor: context-menu;
  }
}
