@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.communications {
  @extend .card;
  flex: 1;

  h3 {
    @extend .card_title;
  }

  &__tabs {
    ul {
      display: flex;
      border-bottom: 1px solid #d3d3d3;
      margin-bottom: 15px;
    }

    &--tab {
      list-style: none;
      color: $subheader-background;
      padding: 6px 12px;
      font-size: 14px;
      margin-right: 10px;
      background-color: white;
      border-radius: 5px 5px 0 0;
      cursor: pointer;
      background-color: #f1f1f1;

      &.current {
        color: white;
        background-color: $green;
      }
    }
  }
}
