@import '../../styles/variables';

.users-in-charge-tooltip {
  background-color: white;
  border: none;
  color: black;
  padding: 10px 15px;
  font-size: 14px;

  &__title {
    margin-bottom: 10px;
  }

  &__user {
    border-top: 1px solid #e1e1e1;
    padding: 6px 0;
    cursor: pointer;
  }

  &__fullname {
    font-weight: 600;
  }

  &__email {
    color: gray;
  }

  &__warning {
    color: $warning;
  }
}
