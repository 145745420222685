@import '../../styles/common.scss';
@import '../../styles/variables';

.area-relationships-page {
  @extend .card;
  min-width: 750px;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  &__header {
    display: flex;
    margin-bottom: 15px;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid silver;
    padding-bottom: 15px;
  }

  &__message {
    font-size: 14px;
    color: $warning;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid $warning;
    margin-bottom: 10px;
    line-height: 20px;

    ul {
      margin-top: 4px;
      margin-left: 20px;
    }
  }

  &__area-name {
    font-size: 20px;
    font-weight: bold;
    width: fit-content;
  }

  &__related-areas {
    display: flex;
    align-items: center;
    width: 25%;
  }

  &__content {
    display: flex;
    gap: 20px;
    flex: 1;
  }

  &__empty-user {
    margin-top: 10px;
    display: flex;
    gap: 25px;
    align-items: flex-start;
    justify-content: center;
    font-size: 22px;
    color: silver;
    padding: 15px;
    padding-top: 80px;
    text-align: center;
    width: 100%;
    border-left: 1px solid silver;
    align-items: flex-start;
  }
}
