.server-error {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  &__message {
    width: 300px;
    color: gray;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    img {
      opacity: 0.5;
      margin: 20px;
    }
    h3 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    p {
      font-size: 21px;
    }
  }
}

// Responsive

@media (max-width: 600px) {
  .server-error {
    flex-direction: column;
  }
}
