@import '../../styles/common.scss';
@import '../../styles/variables.scss';

.assistance-form {
  @extend .card;
  min-width: 750px;

  &__title {
    @extend .card_title;
  }

  th,
  td {
    text-align: center;
    flex: 1;
  }

  td {
    padding: 4px;
  }

  .image-container {
    display: flex;
    justify-content: center;

    div {
      cursor: pointer;
    }
  }

  &__search-container {
    width: 20%;
    margin-bottom: 20px !important;
  }

  &__table-header {
    background-color: rgb(230, 230, 230);
  }

  &__actions {
    display: flex !important;
    align-items: center !important;
    justify-content: center;

    input {
      width: 55px;
      padding: 10px !important;
    }

    svg {
      margin-left: 15px;
      cursor: pointer;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .input-new {
    margin-right: 40px;
  }
}
