.feedback-form {
  &__input {
    margin: 20px 0;

    div {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
