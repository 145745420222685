.ponderations {
  color: black;
  padding: 7px;

  &__title {
    margin-bottom: 5px;
    font-weight: bolder;
    font-size: 12px;
  }

  &__percentages {
    li {
      height: 20px;
      padding: 2px 0;
      list-style: none;
    }
  }
}
