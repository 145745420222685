@import '../../styles/common';
@import '../../styles/animations';

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s;

  &__container {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px 20px 25px;
    border-bottom: 1px solid #e0e0e0;

    &__close-button {
      color: $primary-text-color;
    }
  }

  &__body {
    flex: 1;
    padding: 25px;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    padding: 25px;
    display: flex;
    justify-content: flex-end;
  }
}
