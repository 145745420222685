@import '../../../styles/variables';

.competence-item {
  padding: 15px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 6px;

  &--selected {
    border: 1px solid $green;

    .competence-item__name {
      color: $green;
    }
  }

  &__name-container {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 600;
    width: fit-content;
  }

  &__roles {
    font-size: 12px;
    margin-left: 20px;

    span {
      margin-right: 5px;
      padding: 5px 10px;
      background-color: #f0f0f0;
      border-radius: 12px;
    }
  }

  &__detail {
    padding: 35px 15px 15px;
    line-height: 22px;
    margin-top: 15px;
    border-top: 1px solid #dddddd;
    position: relative;

    p {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  &__edit-button {
    position: absolute;
    top: 10px;
    right: 0px;
  }

  &__options {
    list-style: none;
    padding: 0;
    margin-left: 40px;
  }
}
