@import '../../styles/common';
@import '../../styles/animations';
@import '../../styles/variables.scss';

.previous-goals-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
    margin-bottom: 5px;
  }
}
