@import '../../../styles/common.scss';

.area-filter {
  @extend .card;
  width: 100%;

  &__header-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 20px;
  }

  &__search-container {
    flex: 1;
  }
}
