@import '../../../styles/variables.scss';

.benefit {
  position: relative;
  height: 300px;
  width: 300px;
  color: white;
  animation: moveFromBottom 1s ease-out;

  &__side {
    height: 300px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in;
    border-radius: 8px;

    &--front {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &--back {
      transform: rotateY(180deg);
      display: flex;
      flex-direction: column;
      padding: 40px 20px;
      line-height: 22px;
    }
  }

  &:hover &__side--front {
    cursor: pointer;
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    cursor: pointer;
    transform: rotateY(0);
  }

  &__description {
    flex: 1;
    font-size: 16px;
    overflow: auto;
    margin-bottom: 15px;
  }

  &__title-container {
    font-size: 20px;
  }

  &__discount-container {
    padding: 15px;
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      background-color: $green;
    }

    button:hover {
      background-color: $green-hover;
    }
  }

  &-applicants__quantity {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    border-radius: 50%;
    background-color: $green;
    top: -12px;
    right: -12px;
    border: 1px solid white;
  }

  &-requested {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: white;
    top: -15px;
    left: -15px;
  }

  &__coins {
    position: absolute;
    left: 110px;
    top: -20px;
    display: flex;
    align-items: center;

    &__quantity {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-color: #ffffff;
      padding: 2px 6px;
      border-radius: 5px;
      color: #495183;
      border: 1px solid #495183;
      font-weight: bold;
      font-size: 16px;
      margin-left: -30px;
      width: 65px;
    }
  }
}

@keyframes moveFromBottom {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  50% {
    transform: translateY(-20px);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
