@import '../../../styles/common.scss';

.congratulation-form {
  &__header {
    margin-bottom: 10px;
  }
  &__textarea {
    @extend .textarea;
    height: 150px;
  }
}
