@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.competence-assign {
  margin-top: 10px;

  &__header {
    margin-top: 25px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid silver;
  }

  &__wrapper {
    display: flex;
    border: 1px solid silver;
    margin: 15px 0;
    background-color: #e2e2e2;
    border-radius: 6px;
    height: 252px;
  }

  &__assigned,
  &__all {
    flex: 1;
    padding: 0 5px;
  }

  &__all {
    overflow-y: scroll;
  }

  &__filter {
    width: 50%;
    margin-top: 10px;
  }

  &__assigned {
    border-right: 1px solid silver;

    &__empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #a7a7a7;
      width: 100%;
      height: 100%;
      text-align: center;

      &__title {
        flex: 1;
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;
        font-size: 24px;
      }

      &__message {
        flex: 1;
        display: flex;
        align-items: flex-start;
        font-size: 14px;
      }
    }
  }
}
