@import '../../styles/animations';
@import '../../styles/common';
@import '../../styles/variables';

.dashboard-page {
  display: flex;
  height: 100%;
  gap: 20px;
  animation: fadeIn 1s;

  &__data {
    flex: 1;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &__data--top {
    height: 40vh;
    display: flex;
    gap: 20px;

    .area-users-data {
      overflow: auto;
    }
  }

  &__data--bottom {
    display: flex;
    height: 60vh;
    gap: 20px;
  }

  // &_data-container {
  //   display: flex;
  //   flex-direction: column;
  //   flex: 1;
  //   min-width: 400px;
  // }

  // &_progress-container {
  //   margin-bottom: 20px;
  //   height: 320px;
  // }

  // .categories-ranking {
  //   height: fit-content;
  // }

  // .communications {
  //   height: 60vh;
  // }

  // &_categories-ranking-container {
  //   max-height: 60%;
  //   flex: 1;

  //   &--admin {
  //     max-height: unset;
  //   }
  // }
}

// Responsive

@media (max-width: 600px) {
  .dashboard-page {
    display: block;

    &_data-container {
      margin-left: 0;
      margin-bottom: 15px;
    }

    &_novelties-container {
      margin-left: 0;
    }
  }
}
