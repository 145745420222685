.initial-loading-container {
  position: absolute;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  overflow: hidden;
  opacity: 1;
  transition: all 1s ease-out;
  &--hide {
    opacity: 0;
  }
}

.initial-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: fit-content;

    &__title {
      font-size: 140px;
      color: rgb(164, 176, 186);
    }

    &__subtitle {
      font-weight: 100;
      font-size: 50px;
    }

    &__progress {
      position: relative;
      width: 100%;
      position: absolute;
      left: -3px;
      bottom: -15px;
      background-color: #55556c;
      width: 0px;
      height: 8px;
      border-radius: 15px;
      animation: progres 4.8s linear;
    }
  }
}

@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  60% {
    width: 75%;
  }
  75% {
    width: 90%;
  }
  90% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
