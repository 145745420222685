@import '../../../styles/variables';

.summary {
  border-right: 1px solid silver;
  padding-right: 20px;
  width: 300px;

  &__answered-evaluations {
    margin-top: 15px;
    font-size: 14px;
    color: silver;
  }

  &__competences-score {
    font-size: 14px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid silver;
    border-radius: 6px;
    padding: 10px;
  }

  &__competences-score-qty {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: $subheader-background;
  }

  &__competences-detail-title {
    margin-top: 15px;
    font-weight: bold;
    color: $subheader-background;
  }

  &__competences-detail {
    list-style: none;
    margin-top: 10px;
  }

  &__competence-detail {
    padding: 10px;
    border: 1px solid silver;
    margin-bottom: 5px;
    border-radius: 6px;
  }

  &__competence-name {
    font-size: 16px;
    font-weight: bold;
    color: $subheader-background;
  }

  &__competence-data-container {
    display: flex;
    padding-top: 5px;
  }

  &__competence-data {
    flex: 1;
    font-size: 14px;
  }

  &__comments-title {
    margin-top: 15px;
    font-weight: bold;
    color: $subheader-background;
  }

  &__comments-container {
    list-style: none;
  }

  &__comments {
    margin: 5px 0;
    border: 1px solid silver;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
  }

  &__no-comments {
    color: silver;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
  }
}
