@import '../../../styles/variables';

.competence-user-response {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: 10px;
  padding: 15px 10px;
  border-radius: 6px;
  background-color: #f6f6f6;

  &--evaluated {
    border: 2px solid #6775a0;
  }

  &__name {
    margin-top: 10px;
    font-weight: 600;
  }

  &__direction {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  &__control {
    width: 110px;
  }
}
