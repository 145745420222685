@import '../../styles/common.scss';

.progress-indicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  padding-top: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &__title {
    font-weight: bolder;
    font-size: 14px;
  }

  &__detail {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: zoom-in;
  }
}
