@import '../../styles/common';
@import '../../styles/animations';

.current-evaluations-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-right: 28px;
  }

  &__evaluations {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ececec;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid silver;
  }

  &__evaluation {
    border: 1px solid silver;
    padding: 15px;
    border-radius: 6px;
    background-color: white;
  }

  &__evaluation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__evaluation-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-right: 80px;
  }

  &__evaluation-label {
    font-weight: bold;
    flex: 1;
  }

  &__evaluation-date {
    font-size: 14px;
    margin-right: 80px;
  }

  &__evaluation-completed {
    font-size: 14px;
    font-weight: bold;
  }

  &__evaluation-checkbox {
    width: 40px;
  }

  &__affected-users {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 10px;
    padding-top: 15px;
    border-top: 1px solid silver;
    flex-wrap: wrap;
  }

  &__affected-user {
    display: flex;
    gap: 5px;
    align-items: center;
    border: 1px solid silver;
    border-radius: 4px;
    padding: 0 6px;
    font-size: 14px;

    svg {
      margin-top: 4px;
      font-size: 16px;
    }
  }
}
