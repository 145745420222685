.empty-component-img {
  height: 100%;
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0px auto;

  .img-container {
    img {
      height: 180px;
      filter: grayscale(1);
      opacity: 0.3;
    }
  }

  .dialog {
    border: 1px solid rgb(209, 209, 209);
    margin-bottom: 130px;
    padding: 10px;
    margin-left: -70px;
    border-radius: 15px 15px 15px 0;
    text-align: center;
  }
}
