.user-without-goals {
  padding: 20px 0;
  display: flex;
  gap: 20px;

  &__user {
    border: 1px solid silver;
    border-radius: 10px;
    width: fit-content;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      margin: 10px 0;
    }
  }
}
