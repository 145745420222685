@import '../../../styles/common.scss';

.progress {
  @extend .card;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0px !important;
  flex: 1;

  h3 {
    @extend .card_title;
    width: 100%;
  }

  &-indicators__container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &-total-score {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 1.5rem;
    font-weight: bolder;

    &__total {
      margin-right: 15px;
    }

    &__category,
    &__no-category {
      padding: 4px 6px;
      margin-left: 10px;
      border-radius: 3px;
      font-size: 10px;
    }

    &__no-category {
      color: gray;
      border: 1px solid gray;
    }
  }

  &_empty-state {
    text-align: center;
    padding: 20px;
  }

  &__not-evaluable {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: silver;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
