@import '../../../styles/common.scss';

.goal-devolution-form-textarea {
  @extend .textarea;
  margin-top: 5px;
  height: 150px;

  &__characters {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.goal-devolution-form__details {
  background-color: #f5f4f4;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: -13px;
  border-radius: 6px;

  p {
    margin: 5px 0;

    .goal-devolution-form__name {
      margin-left: 5px;
    }
  }
}

.condition-masssage {
  font-size: 14px;
  margin-top: -5px;
  margin-bottom: 20px;
}
