@import '../../styles/variables.scss';

.user-section {
  display: flex;
  align-items: flex-end;

  &__user-coins {
    margin-right: 40px;
    cursor: pointer;
  }

  &__notifications {
    margin-right: 40px;
  }

  &__user-menu {
    button {
      color: $secondary-text-color !important;
      font-weight: 200 !important;
    }
  }

  &__username {
    margin-left: 10px;
  }

  &__notification-qty {
    margin-left: 10px;
  }
}

// Responsive

@media (max-width: 600px) {
  .user-section {
    &__user-coins {
      margin: 0 10px;
    }

    &__notifications {
      margin: 0 10px;
    }

    &__username {
      display: none;
    }
  }
}
