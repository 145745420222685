.no-exchange-filter {
  width: 205px;
  border: 1px solid silver;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  padding: 15px 50px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover {
    background-color: #e2e2e2;
  }
}
