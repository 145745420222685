.rich-text {
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
  }

  li {
    display: list-item;
  }
}

.jodit-workplace {
  padding: 15px;
}

.jodit-status-bar {
  display: none;
}
