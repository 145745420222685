@import '../../styles/common.scss';
@import '../../styles/variables.scss';

.company-page {
  @extend .card;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  &__content {
    display: flex;

    h1 {
      margin: 15px 0 0 25px;
    }
  }

  &__description {
    padding-right: 25px;
    margin: 25px;
  }
}
