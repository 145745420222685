@import '../../styles/animations';
@import '../../styles/common.scss';

.recognitions-page {
  @extend .card;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  &__container {
    display: flex;
    gap: 35px;
    height: 100%;
  }

  &__manifest {
    width: 480px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;

    @media (max-width: 1500px) {
      width: 350px !important;
    }

    p {
      margin-bottom: 10px;
      line-height: 20px;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 20px;
  }

  &__content {
    display: flex;
    flex: 1;
  }
}
