@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import '../../../styles/common';
@import '../../../styles/variables';

.goal-todo-form {
  &__input {
    border: none;
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid silver;
    background-color: transparent;
    margin: 0 0 10px;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }

  &__text {
    font-size: 12px;
  }

  &__todo-list {
    margin-top: 14px;
    max-height: 400px;
    overflow: auto;
  }

  &__empty-list {
    flex: 1;
    margin-top: 20px;
    text-align: center;
  }

  .goal-todo {
    display: flex;
    justify-content: space-between;

    &-done {
      span {
        text-decoration: line-through;
      }
      .checked {
        svg {
          color: $green;
        }
      }
    }

    .delete-todo-icon {
      color: $warning;
    }
  }

  &__progress {
    padding: 0 15px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .progress-line {
      height: 12px;
      border-radius: 10px;
    }
  }
}
