@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.recognitions {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    .recognition-wrapper {
      transform: scale(1);
      width: 31%;
      height: 165px;
    }

    .recognition {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      border-radius: 8px;
      border: 1px solid gray;

      &--own {
        border-color: $green;
        border-width: 2px;

        .recognition__reason {
          font-weight: bold;
          background-color: #d8eed8;
        }

        .recognition__congratulations {
          background-color: $green-hover;
        }

        .recognition__image {
          background: #d8eed8;
          background-position-x: -15px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      &__image {
        width: 30%;
        height: 100%;
        background-position-x: -25px;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &__confetti {
        position: absolute;
        top: -34px;
        right: -49px;
        width: 57%;
        opacity: 0.3;
        transform: rotate(24deg);
      }

      &__reason {
        width: 70%;
        padding: 18px 10px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 15px;
      }

      &__congratulations {
        position: fixed;
        top: -6px;
        right: -4px;
        font-size: 14px;
        padding: 4px 10px;
        background-color: $green;
        border: 1px solid $green;
        color: white;
      }
    }
  }
}

// Responsive

@media (max-width: 1500px) {
  .recognition-wrapper {
    width: 48% !important;
  }
}
