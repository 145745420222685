.calendar {
  height: 100%;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }

  &__select-mode {
    margin-bottom: 15px;
  }

  &__month {
    text-align: center;
    font-size: 20px;
  }

  &__container {
    display: flex;
    height: 100%;
  }

  &__picker-container {
    width: 350px;
  }

  &__mode-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__month-container {
    flex: 1;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto 15px;

    span {
      cursor: pointer;
    }
  }

  &__month-mode {
    display: flex;
    gap: 15px;
    height: 100%;
  }

  &__days-labels {
    display: flex;
    font-weight: 800;
    font-size: 18px;
    margin-left: 10px;
    border-right: 1px solid silver;

    div {
      width: 14.2%;
      padding: 5px;
      text-align: center;
      background-color: white;
      border-left: 1px solid silver;
    }

    &--week-mode {
      div {
        flex: 1;
        width: none;
      }
    }
  }

  &__days-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    border-right: 1px solid silver;
    margin-left: 10px;
  }

  &__day {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 14.2%;
    border-left: 1px solid silver;
    margin: 0;
    padding: 10px 0;
    min-height: 100px;
    cursor: pointer;
    background-color: #f9f6f6;

    &--week-mode {
      flex: 1;
      width: none;
    }

    &--selected-week {
      border-top: 1px solid #8b9ecb;
      border-bottom: 1px solid #8b9ecb;
      background-color: #efeffa !important;

      .calendar__day__number {
        font-weight: bold;
      }
    }

    &--selected {
      background-color: #efeffa !important;

      .calendar__day__number {
        background-color: #4586cc;
        color: white;
      }
    }

    &--today {
      background-color: #f4f4fa !important;
      .calendar__day__number {
        background-color: #435b99;
        color: white;
      }
    }

    &--active {
      background-color: white;
    }

    &__number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 6px;
    }

    &__large-events,
    &__events {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 4px;
    }

    &__large-events {
      border-bottom: 1px solid silver;
      margin-bottom: 4px;
    }

    &__events {
      padding: 0 4px;
    }

    &__event {
      width: 100%;
      background-color: #729fda;
      border-radius: 6px;
      padding: 8px;
      color: white;
      font-size: 12px;
    }

    &__large-event {
      width: 101%;
      height: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #336fbe;
      color: white;
      font-size: 12px;
      z-index: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &--empty-space {
        background-color: transparent !important;
      }

      &--starts {
        margin-left: 2px;
        padding-left: 8px !important;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &--ends {
        margin-right: 2px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}

.calendar-picker {
  .calendar__days-labels {
    font-size: 12px;
    font-weight: bolder;
  }

  .calendar__day {
    min-height: 40px;
    font-size: 14px;
  }

  .calendar__day__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .calendar__buttons {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto 20px;

    span {
      cursor: pointer;
    }
  }
}
