@import '../../styles/common';
@import '../../styles/animations';

.evaluations-history-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }
}
