$progress-competences: #fff042;
$progress-objetives: #43c743;
$progress-assistance: #a957a6;

//Text Color
$primary-text-color: #747171;
$primary-text-color-light: #c0c0c0;
$secondary-text-color: #f5f4f4;

// Background de los títulos de las cards
$background-title: #82a1c7;

//Background Buttons
$background-button-search: #3a9886;
$background-button-search-hover: #1c5e52;
$background-button-search-disabled: #b9ab91;

// Green
$green: #36b29a;
$green-hover: #2d927e;
$green-disabled: #b9ab91;

$green-secondary: #b1e5db;

// Shadows
$primary-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
$secondary-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.3);
$shadow-light: 0 0 2px 1px rgba(0, 0, 0, 0.2);

// Body
$body-background: rgba(0, 0, 0, 0.1);

// Header
$header-background: #061921;

//Subheader#9c3a3a
$subheader-menu-background: #dfdfdf;
$subheader-background: #093f5c;
$subheader-background-hover: #08364e;

//Warning
$warning: #df8932;
$warning-dark: #c47424;
$warning-light: #faf0e7;
$warning-light-secondary: #eddfd1;

$child-cards-background: #f0f0f0;

// Border
$border-light: #e2e2e2;
