@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600&display=swap');
@import './styles/variables';
@import '/src/components/InnerStateStatus/styles';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;
  background: $body-background;
  color: $primary-text-color;
}

.app {
  height: 100vh;
}

.link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

// Boton de agregar
button.add-button {
  background-color: $green;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  width: 32px;
  height: 30px;
  min-width: 20px !important;
}
button.add-button:hover {
  background-color: $green-hover;
}

.own {
  font-weight: bolder;
  color: black;
}

//Inputs de formularios
.form-input-wrapper {
  height: 60px;
  margin-bottom: 15px;

  &.autocomplete {
    height: fit-content;
  }
}

//Textareas de formularios
.form-multiline-wrapper {
  margin-bottom: 20px;
}

// Custom Scroll
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
