.competence-response {
  padding-bottom: 15px;
  border-bottom: 1px solid silver;
  margin-bottom: 5px;

  &__container {
    display: flex;
  }

  &__questions {
    flex: 1;
  }

  &__user {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
    height: 100%;
    text-align: center;
    padding: 25px 10px;
    margin: 30px 10px;
    border-radius: 6px;
    background-color: #f6f6f6;

    &__name {
      margin-top: 20px;
      font-weight: 600;
    }

    &__direction {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  &__label {
    margin: 32px 15px 15px;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 600;
    border-top: 1px solid #cbcbcb;
  }

  &__option {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin: 10px 15px;
    border-radius: 6px;
    border: 1px solid silver;
    cursor: pointer;
    background-color: #fefefe;

    &:hover {
      background-color: #f6f6f6;
    }

    &__circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid silver;
      margin-right: 15px;
      background-color: white;
    }

    &__label {
      flex: 1;
      line-height: 22px;
    }

    &--disabled {
      cursor: default;

      &:hover {
        background-color: #fefefe;
      }
    }
  }

  .option-selected {
    background-color: #7f90c1;
    border-color: #6775a0;
  }

  &__score {
    display: flex;
    padding: 5px;

    &__points {
      background-color: #fefefe;
      font-size: 24px;
      font-weight: bold;
      padding: 4px 15px;
      border-radius: 6px;
      border: 1px solid silver;
      margin: 0 10px;
      cursor: pointer;

      &:hover {
        background-color: #f6f6f6;
      }
    }

    &--disabled {
      cursor: default;

      &:hover {
        background-color: #fefefe;
      }
    }
  }

  &__score-max-min {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 302px;
    margin-top: 10px;
    margin-left: 15px;
    font-size: 14px;
    color: silver;

    &__label {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__comments {
    margin: 30px 50px 0;
    width: 60%;
    box-sizing: border-box;
  }

  .score-selected {
    color: white;
    background-color: rgb(127, 144, 193);
  }

  &__users {
    display: flex;
    border-top: 1px solid silver;
    margin-top: 20px;
    padding-top: 20px;
    flex-wrap: wrap;
  }

  .max-icon,
  .min-icon {
    svg {
      font-size: 12px;
    }
  }

  .max-icon {
    margin-right: 10px;
  }

  .score-line {
    height: 1px;
    border-bottom: 1px solid #e4e4e4;
    flex: 1;
    margin: 2px 10px 0;
  }

  .min-icon {
    margin-left: 10px;
  }
}
