@import '../../../styles/variables.scss';

.recognition__reactions {
  position: fixed;
  bottom: -4px;
  right: -4px;
  display: flex;
  background-color: white;
  border: 1px solid silver;
  border-radius: 16px;
  height: 30px;
  padding: 2px 0;
  z-index: 11;

  &--list {
    display: flex;
  }

  &--reaction {
    cursor: pointer;
    padding: 0 6px 0 3px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 3px;
    border-radius: 10px;
    font-size: 16px;
    color: #a1a1a1;
    border: 1px solid transparent;

    &:hover {
      background-color: #ebebeb;
    }

    &.reacted {
      border: 1px solid silver;
      background-color: #f1f1f1;
    }
  }

  &--reaction-qty {
    font-size: 14px;
  }

  .add-reaction-icon {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 14px;
    padding: 3px 5px;

    &:hover {
      cursor: pointer;
      background-color: #f0f0f0;
    }
  }
}

.select-reaction-icons {
  position: fixed;
  bottom: -4px;
  right: -4px;
  background-color: white;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  max-height: 0px;
  overflow: hidden;
  transition: all ease 0.5s;
  border: 1px solid silver;
  padding-bottom: 28px;

  &.show {
    max-height: 206px;
  }

  .icon {
    cursor: pointer;
    font-size: 16px;
    padding: 2px 3px;
    border-radius: 50%;
    margin-bottom: 4px;

    &:hover {
      background-color: #edecec;
    }

    &.disabled {
      filter: grayscale(100%);
      background-color: white;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
