@import '../../../styles/common.scss';

.demands {
  display: flex;
  flex-direction: column;
  // @extend .card;
  width: 100%;

  &__columns-container {
    display: flex;
    height: 100%;
  }

  &__column {
    @extend .card;
    padding: 10px 15px;
    height: 100%;
    width: 25%;
    margin: 0 10px;

    h3 {
      text-align: center;
      padding-bottom: 10px;
      color: rgba(0, 0, 0, 0.5);
    }

    &__cards {
      border-radius: 8px;
      flex: 1;
      // padding: 8px;
      // background-color: rgba(233, 233, 233, 0.8);
      // border-top: 1px solid rgba(95, 95, 95, 0.4);
    }

    &-pending {
      border: 2px solid rgba(240, 175, 55, 0.4);
    }

    &-wip {
      border: 2px solid rgba(111, 216, 251, 0.4);
    }

    &-blocked {
      border: 2px solid rgba(214, 68, 68, 0.4);
    }

    &-done {
      border: 2px solid rgba(88, 209, 58, 0.4);
    }

    &__demand {
      margin-bottom: 15px;
    }
  }
}

// Responsive

@media (max-width: 600px) {
  .demands {
    &__columns-container {
      display: flex;
      flex-direction: column;
    }

    &__column {
      width: 100%;
      margin: 5px 0;
    }
  }
}
