@import '../../styles/common';

.new-post-page {
  @extend .card;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;

    span {
      cursor: pointer;
    }
  }

  &__form-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__channel {
    display: flex;
    align-items: center;
    gap: 50px;

    button {
      width: 200px;
    }
  }

  .jodit-container:not(.jodit_inline) .jodit-wysiwyg {
    padding: 25px 10px;
  }
}
