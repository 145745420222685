.demands_filter {
  margin: 0 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding-bottom: 10px;

  .point-icon {
    transform: rotate(90deg);
  }

  button {
    margin-right: 10px;
  }
}
