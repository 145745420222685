@import '../../styles/common';
@import '../../styles/animations';
@import '../../styles/variables';

.create-evaluation {
  @extend .card;
  flex: 1;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  &__no-evaluation,
  &__evaluation-exists,
  &__boss-evaluations {
    font-size: 24px;
    text-align: center;
    padding: 40px 0;
    flex: 1;

    p {
      margin-bottom: 15px;
      padding: 0 50px;
    }
  }

  &__boss-evaluations {
    border-left: 1px solid silver;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__create-button,
  &__close-process {
    color: white;
    padding: 15px 25px;
    width: fit-content;
    border-radius: 6px;
    cursor: pointer;
    margin: 40px auto;
  }

  &__create-button {
    background-color: $green;

    &:hover {
      background-color: $green-hover;
    }
  }

  &__warnings {
    border-radius: 6px;
    padding: 15px;
    color: $warning;
    border: 1px solid $warning;

    p {
      margin-bottom: 15px;
      font-weight: 600;
    }
  }

  &__list {
    display: flex;

    ul {
      flex: 1;
      margin-left: 20px;
      padding-right: 30px;

      li {
        margin-bottom: 5px;
      }
    }
  }

  &__actions {
    justify-content: space-between;
    margin-top: 20px;
  }
}

.sending-message__wrapper {
  margin: 20px auto 0;
  padding: 0 30px;
  font-size: 16px;
  width: 500px;
}

.sending-message {
  margin: 15px 0 15px;

  p {
    margin-bottom: 10px;
  }
}
