.game-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__img-container {
    height: 200px;
    margin-top: -120px;

    img {
      height: 100%;
    }
  }

  &__text {
    padding: 10px;
    text-align: center;
    h3,
    h2 {
      padding: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h4 {
      margin-top: 20px;
    }
    a {
      margin-left: 8px;
      color: rgb(84, 104, 169);
    }
  }

  &__back-button {
    cursor: pointer;
    img {
      width: 200px;
    }
  }
}
