@import '../../../styles/variables.scss';

.assigned-goals-users {
  &__users {
    display: flex;
    padding: 10px 0;
    gap: 20px;
    margin: 10px 0 0;

    .avatar {
      cursor: pointer;
    }

    .user-selected {
      padding-bottom: 10px;
      border-bottom: 3px solid $green;
    }
  }

  &__remove-filter {
    cursor: pointer;
  }
}
