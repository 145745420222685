@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.recognitions-ranking-container {
  margin-top: 20px;
  border-top: 1px solid silver;
  padding-top: 20px;

  h4 {
    margin-bottom: 20px;
  }

  .recognitions-ranking {
    height: 100%;

    .reco {
      padding: 10px 5px;
      padding-left: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #d2d2d2;

      &:hover {
        background-color: #f3f3f3;
      }

      &-name {
        display: flex;
        align-items: center;
      }

      &-star {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      &-qty {
        margin-right: 10px;
      }

      &-text {
        font-size: 12px;
      }
    }
  }
}
