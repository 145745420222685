@import '../../styles/common';
@import '../../styles/animations';
@import '../../styles/variables';

.manage-compentences {
  @extend .card;
  flex: 1;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  .scroll-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
  }
}

.manage-competences__templates {
  @extend .card;
  flex: 1;
  animation: fadeIn 1s;

  h3 {
    @extend .card_title;
  }

  &-container {
    display: flex;
    gap: 20px;
  }

  .competence-template {
    padding: 10px;

    &__name {
      font-weight: bolder;
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__for-role {
      background-color: $green;
      padding: 4px 12px;
      border-radius: 8px;
      width: fit-content;
      color: white;
    }

    ul {
      list-style: none;
      gap: 5px;
      margin-top: 10px;
    }

    &__competence {
      padding: 10px 15px;
      margin: 4px 0;
      border-radius: 6px;
      background-color: #e1e1e1;
    }
  }
}
