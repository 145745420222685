@import '../../styles/common.scss';
@import '../../styles/variables.scss';

.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  justify-content: center;
  background-image: url('../../assets/bg-image.jpg');
  background-size: cover;
  background-position: center;

  &__logo {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      font-size: 24px;
      color: black;
      font-weight: 300;
      margin: 0;
    }

    h2 {
      font-size: 16px;
      color: rgb(80, 84, 117);
      font-weight: 200;
      letter-spacing: 2px;
      margin: 0;
    }
  }

  &__title {
    font-weight: 300;
    margin-bottom: 20px;
  }

  &__description {
    width: 80%;
    margin-bottom: 20px;
  }

  &_form-wrapper {
    width: 600px;
    padding: 20px 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
      width: 80%;
    }
  }

  &_form-container {
    @extend .card;
    width: 100%;
    height: auto;
    align-items: center;
    padding: 50px 0px;
  }

  &_inputs-wrapper {
    display: flex;
    flex-direction: column;

    .login-page_input {
      margin-bottom: 20px;
      border-radius: 6px;
    }

    button {
      padding: 10px;
      background-color: $green;

      &:hover {
        background-color: $green-hover;
      }
    }
  }

  &__forgot-password {
    width: 100%;
    color: $green;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;

    a:hover {
      color: $green-hover;
    }

    a:visited {
      color: $green;
    }
  }
}

@media (max-width: 800px) {
  .login-page {
    &_img-wrapper {
      min-width: 500px;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &_form-wrapper {
      min-width: 500px;
      width: 100%;
    }
    &_form-container {
      z-index: 10;
      background-color: white;
    }
  }
}
