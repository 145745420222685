@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.demand {
  @extend .card;
  padding: 10px;
  position: relative;

  &__edited {
    position: absolute;
    top: -2px;
    right: -2px;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: $subheader-background;
    color: white;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_title {
    flex: 1;
    margin: 10px 0;
  }

  &_footer-date {
    font-size: 12px;
    color: $primary-text-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin-bottom: -5px;
  }

  &-creator {
    font-size: 12px;

    span {
      font-style: italic;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &_expired-date {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bolder;
    background: white;
    padding: 2px;
    width: 22px;
    height: 22px;

    &.zero {
      color: red;
    }
  }

  &.pending {
    background-color: #fff3de;
  }

  &.wip {
    background-color: #e1f8ff;
  }

  &.blocked {
    background-color: #ffdbdb;
  }

  &.done {
    background-color: #e0ffe0;
  }
}
