@import '../../../styles/common.scss';

.recognition-form-textarea {
  @extend .textarea;
  height: 100px;
}

.radio-button-group {
  margin: 15px 0;
}
