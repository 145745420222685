.excel-icon {
  padding: 5px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;

  img {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    border-bottom: 1px solid green;
  }
}
