.competence {
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: fit-content;
  padding: 10px;
  background-color: #fcfcfc;
  margin: 5px 0;
  cursor: pointer;

  &__name {
    display: flex;
    align-items: center;
    margin: 0 25px;
  }

  &__detail-icon {
    margin-left: 25px;
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }
}
