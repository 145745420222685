.competence-modal-form {
  .form-control,
  h4,
  hr {
    margin-bottom: 20px;
  }

  &__actions {
    margin-top: 20px;
  }
}
