@import '../../../styles/variables';

.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  &__cover-container {
    width: 100%;
  }

  &__cover {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-bottom: 40px;
  }

  &__image {
    width: 40%;

    img {
      width: 100%;
    }
  }

  &__text {
    flex: 1;

    h3 {
      margin-bottom: 20px;
      font-size: 24px;
    }

    p {
      margin-bottom: 10px;
      line-height: 20px;
    }
  }

  &__allowed,
  &__not-allowed {
    padding: 10px 20px;
    border-radius: 6px;
    margin-top: 25px;
    font-size: 14px;
  }

  &__allowed {
    color: $green;
    border: 1px solid $green;
  }

  &__not-allowed {
    color: #989694;
    border: 1px solid #989694;
  }

  &__categories-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &__user-category {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;

    &__points {
      font-size: 16px;
      font-weight: bold;
    }

    &__category-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    &__category {
      padding: 4px 6px;
      margin-left: 10px;
      border-radius: 3px;
      font-size: 10px;
    }
  }

  &__categories {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;

    &__category {
      margin: 0 10px;
      position: relative;
      border: 1px solid silver;
      border-radius: 6px;
      padding: 0;
      cursor: pointer;
      box-shadow: $shadow-light;
      color: gray;
      display: flex;
      flex-direction: column;
      padding: 15px 15px 15px 50px;
      z-index: 2;
      width: 205px;
      align-items: flex-end;
      background-color: #e2e2e2;

      &:hover {
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
      }

      &__name {
        font-weight: bold;
      }

      &__min-points {
        font-size: 12px;
      }
    }

    &__coins {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }

  &-container {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    border-top: 1px solid silver;
    flex: 1;
    justify-content: space-between;
  }

  &__empty-title {
    width: fit-content;
    margin: auto;
    color: rgb(215, 215, 215);
    font-size: 32px;
  }

  &__credential-examples {
    display: flex;
    gap: 25px;
  }
}

.Bronze,
.Silver,
.Gold {
  position: relative;
  background-color: #e2e2e2;
}

// .Bronze {
//   border: 1px solid rgb(245, 213, 194);
//   // background: rgb(255, 232, 195);
//   // background: linear-gradient(
//   //   346deg,
//   //   rgba(255, 232, 195, 1) 21%,
//   //   rgba(255, 255, 255, 1) 45%,
//   //   rgba(245, 220, 184, 1) 76%
//   // );
// }

// .Silver {
//   border: 1px solid rgb(215, 251, 251);
//   // background: rgb(233, 233, 233);
//   // background: linear-gradient(
//   //   346deg,
//   //   rgba(233, 233, 233, 1) 21%,
//   //   rgba(255, 255, 255, 1) 45%,
//   //   rgba(221, 221, 221, 1) 76%
//   // );
// }

// .Gold {
//   border: 1px solid rgb(250, 242, 131);
//   // background: rgb(247, 246, 220);
//   // background: linear-gradient(
//   //   346deg,
//   //   rgba(247, 246, 220, 1) 21%,
//   //   rgba(255, 255, 255, 1) 45%,
//   //   rgba(240, 229, 154, 1) 76%
//   // );
// }

.selected {
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
  color: #5184be;
  border: 1px solid #5184be;
  background-color: white;
}

.Bronze-background,
.Silver-background,
.Gold-background {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: 45px;
  background-repeat: no-repeat;
}

.Bronze-background {
  background-image: url('../../../assets/bronze.png');
  background-position: 5px -23px;
}

.Silver-background {
  background-image: url('../../../assets/silver.png');
  background-position: 5px -23px;
}

.Gold-background {
  background-image: url('../../../assets/gold.png');
  background-position: 5px -26px;
}
