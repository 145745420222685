.company-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 40px;
  width: fit-content;
  min-width: 100%;
  height: 100%;
}
