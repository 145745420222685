@import '../../../styles/variables.scss';

.multiple-evaluations {
  flex: 1;
  max-width: 33%;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  padding: 10px 20px 0;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  background-color: #f5f4f4;

  &__title,
  &__sub-title {
    text-align: center;
  }

  &__title {
    font-weight: 600;
  }

  &__sub-title {
    margin-top: 2px;
    margin-bottom: 30px;
    color: $green;

    &--pendings {
      color: $warning;
    }
  }

  &__carousel {
    display: flex;
    width: fit-content;
    transition: all 0.5s ease-out;

    .user-evaluation {
      flex: unset;
      width: 100%;
      max-width: unset;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 5px;
      border-radius: 50%;
      background-color: silver;
      transition: all 0.2s;

      &--active {
        width: 10px;
        height: 10px;
        background-color: $green;
      }
    }
  }

  .changege-page {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    background-color: $green;
    color: white;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 3px 2px 1px rgba(0, 0, 0, 0.2);
    border: none;
    font-weight: 600;

    &:disabled {
      color: #e4e4e4;
      background-color: #aec2b3;
      opacity: 1;
    }

    &--prev {
      left: 5px;
    }

    &--next {
      right: 5px;
    }
  }
}
