.confirm-modal__warning-icon-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.warning-icon {
  margin: 0 10px;
  font-size: 3rem !important;
  opacity: 0.8;
}
.confirm-modal__title {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 20px;
}
.confirm-modal__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.warning-message {
  text-align: center;
  color: rgb(179, 158, 120);
  font-size: 16px;
}

.select-motive__title {
  text-align: center;
  color: rgb(179, 158, 120);
  font-size: 16px;
  margin-bottom: 20px;
}
