@import '../../styles/variables';

.pending-task {
  background-color: white;
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: $secondary-shadow;
  z-index: 10;
  min-width: 240px;
  border: 1px solid $subheader-background;

  &--success {
    background: $green;
    color: white;
    border-color: $green;

    svg {
      fill: white !important;
    }
  }

  &--error {
    border: 1px solid #f04a4a;
    background-color: #e25a5a;

    svg {
      fill: white !important;
    }
  }

  &__button,
  &__quit-button {
    position: absolute;
    top: 2px;
    right: 0;

    svg {
      fill: silver;
    }
  }

  &__quit-button {
    svg {
      font-size: 16px;
    }
  }

  &__header {
    margin-bottom: 10px;
  }

  &__title {
    margin: 10px 10px 0 0;
    font-weight: bold;
    font-size: 14px;
  }

  &__success-message {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    margin-right: 25px;
    font-weight: bold;

    svg {
      fill: $green;
    }
  }

  &__error-message {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    margin-right: 25px;

    svg {
      fill: white;
    }
  }
}
