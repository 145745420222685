@import '../../styles/common';
@import '../../styles/animations';
@import '../../styles/variables';

.user-evaluations-page {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  &__creation-warning {
    color: $warning;
    border: 1px solid $warning;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
    width: fit-content;

    a {
      color: $warning;
    }
  }

  &__team-evaluations-status {
    margin-bottom: 15px;
  }
}
