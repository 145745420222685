@import '../../styles/common';
@import '../../styles/animations';

.competences-score-detail {
  @extend .card;
  animation: fadeIn 1s;

  &__title {
    @extend .card_title;
  }

  &__content {
    display: flex;
  }
}
