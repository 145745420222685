@import '../../../styles/common.scss';

.benefits-table {
  @extend .card;
  min-width: 750px;

  &__title {
    @extend .card_title;
  }

  th,
  td {
    text-align: center;
    flex: 1;
  }
  tr {
    height: 60px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    div {
      border-radius: 0px !important;
    }
  }

  .icon-container {
    cursor: pointer;
  }

  &__table-header {
    background-color: rgb(230, 230, 230);
  }
}
