.users-search {
  background-color: white;
  height: 36px;
  margin: 0 40px;
  border-radius: 50px;

  .MuiInputBase-root {
    height: 36px;
    padding: 0 12px !important;
    border-radius: 50px;
    opacity: 0.8;
  }
}
