.register-form {
  h3 {
    margin-bottom: 20px;
  }
  &__container {
    display: flex;

    &__side {
      width: 50%;
    }
  }

  .side-left {
    margin-right: 25px;
  }

  .multi-select {
    height: auto;
  }

  &__role-control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }

  &__range {
    width: 80px;
  }
}
