.survey-average-wrapper {
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;

  &--no-data {
    padding: 20px;
    background-color: #f4f4f4;
  }

  &--4 {
    background-color: rgba(150, 189, 145, 0.6);
  }

  &--3 {
    background-color: rgba(237, 234, 180, 0.6);
  }

  &--2 {
    background-color: rgba(229, 204, 159, 0.6);
  }

  &--1 {
    background-color: rgba(221, 163, 149, 0.6);
  }
}

.survey-average {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 7px 15px;
  width: fit-content;
  z-index: 100;
  opacity: 1;

  &__average {
    margin: -5px 15px 0;
    font-size: 28px;
    font-weight: 600;
    line-height: 20px;
    vertical-align: top;
  }

  span {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;

    span {
      font-size: 30px;
      opacity: 0.8;
    }
  }
}
