.goals-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0 5px;

  &__select {
    flex: 1;
  }
}
