@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.manage-users {
  display: flex;
  flex-direction: row;
  height: 100%;
  animation: fadeIn 1s;

  // &__form-container {
  //   @extend .card;
  //   width: 30%;
  //   height: fit-content;
  //   margin: 0 10px 0 0;
  //   h3 {
  //     @extend .card_title;
  //   }
  // }

  .flag-management {
    margin-left: 10px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: rgb(150, 150, 182);
    color: white;
  }

  a,
  a:visited {
    color: #5e5e8b;

    &:hover {
      color: #7171b4;
    }
  }

  .inactive-motive {
    color: $warning;
  }
}
