.postulated-users {
  &__title {
    color: silver;
    font-size: 12px;
    margin-bottom: 10px;

    &--empty {
      color: silver;
      font-size: 12px;
      margin-bottom: 0px;
    }
  }

  &__list {
    list-style: none;

    li {
      margin: 2px 0;
    }
  }
}
