@import '../../../styles/common';
@import '../../../styles/animations';
@import '../../../styles/variables';

.demand-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.3s;

  &__container {
    display: flex;
    position: relative;
    width: 80%;
    height: 90%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s;
  }

  &__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__content {
    width: 60%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: 15px;
    }
  }

  &__static-data {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;

    div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &__editable-data {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    padding-right: 15px;
    padding-top: 10px;

    div {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .demand-form-textarea {
      flex: 1;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    top: 0;
    right: 10px;
    position: absolute;
  }

  &__title {
    font-size: 17px;
    font-weight: bold;
  }

  &__description {
    font-size: 17px;
    line-height: 22px;
  }

  &__comments {
    flex: 1;
    border-left: 1px solid silver;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  &__comments-container {
    margin-top: 15px;
    max-height: 90%;
    overflow-y: scroll;
    padding-right: 25px;
    padding-bottom: 10px;
    flex: 1;
  }

  &__comment {
    margin: 10px 0;
    padding: 6px 10px;
    border: 1px solid #d4d4d4;
    border-radius: 6px;

    &--unread {
      border: 1px solid $green;
    }
  }

  &__comment-data {
    display: flex;
    gap: 10px;
    font-size: 13px;
    color: #a1a1a1;
  }

  &__comment-text {
    margin-top: 6px;
  }

  &__new-comment {
    border-top: 1px solid #ececec;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
