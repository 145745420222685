@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Luckiest+Guy&display=swap');
@import '../../styles/variables.scss';

.game-page {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  background: #4cab99;
}

.board {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.left-side {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .images-wrapper {
    // border: 1px solid red;
    position: relative;
    width: 200px;
    height: 400px;
    justify-self: flex-end;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .show {
      animation: fade-in 0.3s;
      animation-delay: 0.3s;
      animation-fill-mode: backwards;
    }

    .hide {
      animation: fade-out 0.3s forwards;
    }
  }
}

.right-side {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .counter {
    align-self: center;
  }
  .back-button {
    align-self: flex-end;
    cursor: pointer;
    img {
      width: 140px;
    }
  }
}

.memo-user-card {
  position: relative;
  box-shadow: 5px 5px 0 0;
  height: 200px;
  width: 200px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  background: #ffecd0;
  border-radius: 50%;

  &__side {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in;
    padding-top: 10px;

    img {
      border-radius: 50%;
      height: 120px;
      width: 120px;
      border: 1px solid silver;
    }

    &--front {
      background-image: url(../../assets/preg.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--back {
      transform: rotateY(180deg);
    }
  }

  &.clicked {
    .memo-user-card__side--front {
      transform: rotateY(-180deg);
    }
    .memo-user-card__side--back {
      transform: rotateY(0deg);
    }
  }
}

@keyframes fade-in {
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
}
@keyframes fade-out {
  from {
    scale: 1;
  }
  to {
    scale: 0;
  }
}
