@import '../../styles/common';
@import '../../styles/variables';

.children {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;

  .green-line {
    border-color: #9dbc9d;
    border-width: 2px;
  }
}

button {
  padding: 4px 10px;
}

.area-box {
  position: relative;
  border: 1px solid silver;
  padding: 20px 20px 26px;
  width: 220px;
  margin: 0 10px;
  text-align: center;
  background-color: #f7f7f7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;

  &--own-area {
    font-size: 18px;
    background-color: #dee9de;
  }

  &:hover {
    background-color: #eaeaea;
  }

  &__name {
    font-size: 14px;
  }

  &__bosses {
    font-size: 13px;
    font-weight: bold;
    margin: 10px 0;
  }

  &__boss-name {
    margin-bottom: 10px;
  }

  &__no-boss,
  &__no-employees {
    color: $warning;
    font-weight: 600;
  }

  &__view-detail {
    position: absolute;
    bottom: 4px;
    left: 18px;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
    }
  }

  &__relationships {
    position: absolute;
    bottom: 0;
    right: 18px;
    cursor: pointer;
  }

  &__without-boss-icon {
    position: absolute;
    width: 26px;
    height: 26px;
    top: -13px;
    right: calc(50% - 13px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    background-color: white;

    svg {
      font-size: 18px;
    }
  }

  &__members {
    position: absolute;
    width: 26px;
    height: 26px;
    top: -13px;
    right: -13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid silver;
    background-color: white;
    vertical-align: top;
    line-height: 16px;
    font-size: 14px;
    font-weight: bold;
  }
}

.toogle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  color: white;
  border: 1px solid transparent;
  background-color: #83a29c;
  border-radius: 4px;
  width: 40px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: #5f877f;
  }

  &--open {
    color: $subheader-background;
    border: 1px solid gray;
    background-color: white;

    &:hover {
      background-color: #ececec;
    }
  }
}

.space-y {
  width: 100%;
  height: 40px;
}

.connector-x {
  height: 1px;
  border-top: 1px solid silver;
  width: 100%;

  &--left {
    width: 50%;
    margin-left: 50%;
  }

  &--middle {
    width: 100%;
  }

  &--right {
    width: 50%;
    margin-right: 50%;
  }
}

.connector-y {
  width: 1px;
  height: 20px;
  border-left: 1px solid silver;
  margin: 0 auto;
  margin-top: -1px;

  &--direct {
    height: 25px;
  }
}

.organization-chart-page {
  @extend .card;
  animation: fadeIn 1s;
  /* height: 100vh;
  overflow-y: scroll; */

  h3 {
    @extend .card_title;
  }

  &__header {
    border-bottom: 1px solid silver;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    width: 100%;
  }

  &__filter {
    width: 25%;
  }

  .form-input-wrapper {
    margin-left: 15px;
    width: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  .scroll-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
  }
}
