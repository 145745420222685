@import '../../../styles/common.scss';

.benefits-form {
  &-textarea {
    @extend .textarea;
    height: 100px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }
}
