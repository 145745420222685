@import '../../styles/animations';
@import '../../styles/common.scss';

.searches-page {
  animation: fadeIn 1s;
  height: 100%;
  display: flex;
  flex-direction: column;

  &_add-button {
    padding: 10px 0;
  }

  &_container {
    @extend .card;

    h3 {
      @extend .card_title;
    }
  }

  &_searches-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
}
