.layout {
  display: grid;
  grid-template-areas:
    'header'
    'subheader'
    'main';
  grid-template-columns: 1fr;
  grid-template-rows: 3.5rem 3rem 1fr;
  min-height: 100vh;
  animation: fadeIn 1s;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;

  &__section {
    padding: 20px;
    grid-area: main;
    width: 100vw;
    max-width: 100%;
  }
}

// Resposive

@media (max-width: 600px) {
  .layout {
    &__section {
      padding: 0;
      width: 100vw;
      max-width: 100%;
    }
  }
}
