@import '../../styles/variables.scss';
@import '../../styles/common.scss';

.user-info {
  @extend .card;
  width: 300px;
  height: fit-content;
  position: relative;

  .category {
    position: absolute;
    background: $green;
    color: white;
    top: 0;
    left: 9px;
    font-size: 14px;
    padding: 4px 10px;
    border-radius: 0 0 10px 10px;
  }

  p {
    padding: 10px 0;
    display: flex;

    .icon-wrapper {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }

  .img-container {
    border-radius: 50%;
    width: 250px;
    height: 250px;
    box-shadow: 0 3px 7px 1px #cdbcbc;
    margin: 20px auto;

    div {
      width: 100%;
      height: 100%;
    }
  }

  &_data-container {
    border-top: 1px solid silver;
    flex: 1;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 20px;

    &__name {
      font-size: 20px;
      color: $subheader-background-hover;
      margin-bottom: 20px;
    }

    p {
      display: flex;
      align-items: center;
      word-break: break-all;
    }

    .no-area,
    .inactive {
      color: red;
      font-size: 14px;
      background-color: #f9efef;
      padding-left: 10px;
      margin-top: 10px;
    }
  }

  .evaluations-history {
    padding: 10px;
    background-color: $green;

    &:hover {
      background-color: $green-hover;
    }
  }

  .action-plan {
    margin-top: 25px;
    padding: 10px;
    background-color: $green;
    width: 100%;

    &__wrapper {
      position: relative;
    }

    &__badge {
      position: absolute;
      color: white;
      right: -10px;
      top: 12px;
      border-radius: 6px;
      font-size: 12px;
      z-index: 2;
      padding: 4px 6px;

      &.unread {
        background-color: $warning;
      }

      &.read {
        background-color: $green-hover;
      }
    }

    &:hover {
      background-color: $green-hover;
    }
  }

  &__super-admin-message {
    p {
      display: block;
      color: #999999;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }
}

// Responsive

@media (max-width: 600px) {
  .user-info {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 1400px) {
  .user-info {
    width: 280px;

    .img-container {
      width: 220px;
      height: 220px;
    }
  }
}
