.goal__details {
  background-color: #f5f4f4;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: -13px;
  border-radius: 6px;

  p {
    margin: 5px 0;
  }

  .goal__user-name {
    margin-left: 5px;
  }

  .group__usernames {
    .goal__user-name:not(:last-child)::after {
      content: ',';
    }

    .goal__user-name:last-child::before {
      content: 'y ';
    }

    .goal__user-name:last-child::after {
      content: '.';
    }
  }
}
