.demands-page {
  display: flex;
  height: 100%;
  animation: fadeIn 1s;
}

// Responsive

@media (max-width: 600px) {
  .demands-page {
    flex-direction: column;
  }
}
