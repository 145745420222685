@import '../../../styles/variables';

.evaluation-pending {
  padding: 30px 10px 0;
  margin-bottom: 20px;
  border-top: 1px solid silver;

  h2 {
    margin-bottom: 20px;
  }

  &__filters {
    display: flex;
    gap: 25px;
    border-bottom: 1px solid silver;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }

  &__form-control {
    width: 25%;
  }

  &__name {
    display: flex;
    padding-left: 15px;

    &--area {
      cursor: pointer;

      &:hover {
        color: #3c67a9;
      }
    }
  }

  .dependent {
    padding-left: 35px;
  }

  &__users {
    margin-left: 20px;
  }

  &__tasks {
    display: flex;
  }

  &__task {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 14px;
    margin: 0 8px;

    &--pending {
      color: $warning;
      border: 1px solid $warning;
    }

    &--done {
      color: $green;
      border: 1px solid $green;
    }
  }

  tr:hover {
    background-color: #f7f7f7;
  }

  td {
    padding: 10px;
  }
}
