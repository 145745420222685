@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.missing-tasks {
  @extend .card;
  display: flex;
  flex: 1;
  flex-direction: column;

  h3 {
    @extend .card_title;
    margin-bottom: 5px;
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;

    .task {
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 6px;
      justify-content: center;
      border: 1px solid $green;
      color: $green;
      font-size: 14px;
      cursor: pointer;
      padding: 10px;
      text-align: center;

      &:hover {
        color: white;
        background-color: $green;
      }

      &.pending {
        border: 1px solid $warning;
        color: $warning;

        &:hover {
          color: white;
          background-color: $warning;
        }
      }
    }
  }
}
