@import '../../../styles/variables.scss';

.team-evaluations {
  margin: 10px 0;
  padding: 15px 5px 5px;
  border-top: 1px solid silver;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  &__no-evaluations {
    color: silver;
    font-size: 14px;
  }

  &__user {
    box-shadow: $primary-shadow;
    padding: 10px;
    border-radius: 6px;
    width: 248px;

    &-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 6px;
    }

    &-evaluations {
      margin-top: 15px;
    }

    &-evaluation {
      border-bottom: 1px solid #d5d5d5;
      margin-bottom: 10px;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        display: flex;
        align-items: center;
        margin: 0 10px;
      }
    }
  }

  &__user-area {
    margin-top: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
