@import './variables.scss';

.card {
  background-color: white;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: $primary-shadow;
  color: $primary-text-color;
}

.card_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-align: left;
  color: $secondary-text-color;
  background-color: $subheader-background;
  padding: 7px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  height: 40px;
  font-size: 14px;
  box-sizing: border-box;
}

.textarea {
  margin-bottom: 15px;
  display: block;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  resize: none;
  border: 1px solid silver;
  &:hover {
    border: 1px solid rgb(39, 39, 39);
  }
  &:focus {
    border: 2px solid rgb(0, 0, 0);
    padding: 9px;
    outline: none;
  }
  &:focus-visible {
    // outline: -webkit-focus-ring-color auto 1px;
    border: 2px solid rgb(0, 0, 0);
  }
}

// Responsive

@media (max-width: 600px) {
  .card {
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
  }
}
