.crop-image {
  &__input-file {
    border: 1px solid silver;
    padding: 10px;
    border-radius: 6px;
  }

  &__images-container {
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    max-height: 420px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  &__original-image {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: fit-content;
    img {
      width: 280px;
    }
  }

  &__croped-image {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin-bottom: 15px;
    }
  }

  &__message {
    color: silver;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  &__error {
    color: #bb5b5b;
    font-size: 14px;
    margin-top: 15px;
  }
}

.modal__container {
  width: 620px;
}
