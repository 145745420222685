@import '../../../styles/common.scss';

.search-form {
  &-textarea {
    @extend .textarea;
    height: 100px;
  }

  &__empty-message {
    color: rgb(162, 59, 59);
    margin-bottom: 25px;
  }
}
