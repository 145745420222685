@import '../../../styles/common';
@import '../../../styles/animations';
@import '../../../styles/variables.scss';

.without-goals-warning {
  border: 1px solid $warning;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 6px;
  margin: 10px 0;
  color: $warning;

  button {
    padding: 3px 6px;
    background-color: white;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: underline;
    color: $warning;
    font-weight: bold;
  }
}
