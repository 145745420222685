.nine-box {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-top: 15px;

  &__categories-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  &__category {
    width: 33.33%;
    min-height: 170px;
    padding: 10px;
    opacity: 0.7;
    z-index: 1;
    position: relative;

    &-title {
      text-align: center;
      z-index: 2;
      color: black;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 3px 6px;
      border-radius: 4px;
      width: fit-content;
      margin: 0 auto;
    }

    &-info {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &-users {
      padding: 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      .avatar-tooltip {
        max-width: 40px;
      }
      .avatar {
        margin: 3px;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &.boss {
          border: 1px solid rgb(123, 121, 121);
          outline: 2px solid rgb(100, 99, 99);
          outline-offset: 4px;
        }
      }
    }
  }

  &__y {
    width: 8px;
    text-align: center;
    margin-right: 10px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto 15px auto 5px;
    height: 250px;
  }

  &__x {
    text-align: center;
    display: flex;
    justify-content: space-around;
    width: 220px;
    margin: 0 auto;
    margin-top: 15px;
  }
}

.pointless-users-wrapper {
  padding: 15px 15px 5px;
  margin-top: 5px;
  background-color: #f4f4f4;
  border: 1px solid #e6e6e6;
  border-radius: 4px;

  p {
    font-size: 14px;
  }
}

.pointless-users {
  margin: 10px 0;
  display: flex;
  gap: 5px;

  &__empty {
    margin: 15px 0;
  }

  .avatar {
    margin: 0 10px;
    cursor: pointer;
  }
}
