@import '../../../styles/variables';

.sugested-users {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  &__user {
    display: flex;
    align-items: center;
    border: 1px solid silver;
    background-color: white;
    padding: 10px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    opacity: 0.7;

    &--post-evaluation {
      position: fixed;
      top: -4px;
      left: -4px;
    }

    &--selected {
      font-weight: bold;
      border: 2px solid $green;
      opacity: 1;
    }

    &--post-evaluation {
      position: absolute;
      top: 0;
      left: 0;

      svg {
        fill: $warning;
        font-size: 16px;
      }
    }
  }

  &__user-data {
    margin-left: 20px;
  }

  &__user-name {
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2px;
  }

  &__user-role-label {
    font-size: 12px;
  }

  &__user-area {
    margin-left: 15px;
  }

  &__check {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 4px;
    background-color: $green;
    width: 18px;
    height: 18px;
    top: -6px;
    right: -6px;
    color: white;

    svg {
      font-size: 14px;
    }
  }

  &__added-user {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 6px;
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 38px;
    color: gray;

    svg {
      font-size: 14px;
    }
  }

  &__empty {
    padding-left: 10px;
  }
}
