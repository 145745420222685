@import '../../../styles/variables';

.area-users-list {
  margin: 10px 0;

  &__evaluation-status {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 30px;
    font-size: 14px;

    &--created {
      color: $green;
    }

    &--not-created {
      color: $warning;
    }
  }

  &__users {
    border-top: 1px solid silver;
    position: relative;
    margin-bottom: 30px;
  }

  p {
    font-weight: bold;
    background-color: white;
    padding-right: 10px;
    position: absolute;
    top: -10px;
  }

  &__widthout-boss,
  &__without-employees {
    padding-left: 15px;
    margin-top: 30px;
    color: silver;
  }

  &__no-boss,
  &__no-employees {
    margin-top: 30px;
    padding-left: 15px;
    color: $warning;
  }
}
