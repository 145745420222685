@import '../../../styles/variables.scss';
@import '../../../styles/common.scss';

.novelty {
  padding: 0 0 10px;
  display: flex;
  border-top: 1px solid $border-light;

  &:hover {
    background-color: rgb(247, 247, 247);
  }

  &__date {
    width: 60px;
    height: 22px;
    color: white;
    background-color: $green;
    font-size: 11px;
    padding: 2px 5px;
    border-bottom-right-radius: 10px;
    margin-right: 10px;
    font-weight: bold;
  }

  &__text {
    flex: 1;
    padding-top: 8px;
    font-size: 14px;
    line-height: 18px;
  }

  &__label {
    color: rgb(34, 43, 106);
    display: inline-block;
    font-size: 12px;
    margin-left: 4px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__redirect {
    text-decoration: none !important;
  }

  &__chip {
    &:hover {
      cursor: pointer;
    }
  }
}
