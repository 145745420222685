@import '../../../styles/variables';

.evaluation-type {
  width: 100%;
  border: 1px solid silver;
  border-radius: 6px;

  &__header {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 0 15px;
    border-bottom: 1px solid silver;
    height: 41px;

    h4 {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__content {
    display: flex;
  }

  &__sugested-users {
    width: 100%;
    padding: 15px;
    background-color: #f4f4f4;
  }

  &__related-users {
    p {
      display: flex;
      align-items: center;
    }
  }

  &__add-users {
    margin-left: 20px;
    background-color: $green;
    color: white;
    padding: 4px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;

    &:hover {
      background-color: $green-hover;
    }
  }
}
