@import '../../../styles/variables';

.show-impacts-modal {
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  &__impacts {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__impact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid silver;
  }

  &__evaluation-type {
    display: flex;
    gap: 15px;
    align-items: center;
  }
}
