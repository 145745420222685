@import '../../../styles/common.scss';
@import '../../../styles/variables.scss';

.survey {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: #433b3b;

  &__emotions {
    display: flex;
    width: 100%;
    cursor: pointer;

    span {
      font-size: 100px;
      flex: 1;
      opacity: 0.7;
    }
  }

  &__footer {
    width: 100%;

    h3,
    h4 {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        margin: 0 10px;
      }
    }
  }
}
