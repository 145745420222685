.inner-state-loading,
.inner-state-error,
.inner-state-error {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-state-no-data {
  padding: 15px;
  color: gray;
}
